<template>
  <div>
    <el-form ref="form" label-width="300px">
      <MyFormItem label="Table Index" tooltip="Table Index đang active">
        <el-input class="form-input" v-model="data.TableIndex" size="normal" disabled/>
      </MyFormItem>

      <MyFormItem label="Group name" tooltip="Chọn group name cần show leaderboard">
        <el-select v-model="showLeaderBoard.groupName">
          <el-option v-for="data in this.groupTypeSelect" :key="data.value" :label="data.value" :value="data.value"/>
        </el-select>
      </MyFormItem>

      <MyFormItem label="Leaderboard Index" tooltip="Chọn leaderboard index cần show leaderboard">
        <el-input-number v-model="showLeaderBoard.lbIndex" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="showLeaderBoard.result.length > 0">
        <el-collapse-item title="View Data">
          <el-table :data="showLeaderBoard.result" border >
            <el-table-column
                prop="Rank"
                label="Rank"/>
            <el-table-column
                prop="UserId"
                label="UserId"/>
            <el-table-column
                prop="Score"
                label="Score"/>
            <el-table-column
                label="Command"
            >
              <template #default="scope">
                <el-button type="primary" round @click="handleTapShowUserDetail(scope.row)">
                  UserInfo
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <el-button type="primary" @click="getLeaderBoard()">
        Get Leaderboard
      </el-button>

      <br/>
      <br/>

      <div ref="user-info">

        <MyFormItem label="UserId" tooltip="Nhập userId để search thông tin">
          <el-input class="form-input" v-model="showUserInfo.userId" size="normal" clearable/>
        </MyFormItem>

        <el-button type="primary" @click="getUserInfo()">
          Get User Info
        </el-button>

        <br/>
        <br/>

        <el-table :data="showUserInfo.result" border v-if="showUserInfo.result.length > 0">
          <el-table-column
              prop="UserId"
              label="UserId"/>
          <el-table-column
              prop="IsPayUser"
              label="IsPayUser"/>
          <el-table-column
              prop="UserStatus"
              label="UserStatus"/>
          <el-table-column
              prop="GroupNameOnly"
              label="GroupName"/>
          <el-table-column
              prop="TableIndex"
              label="TableIndex"/>
          <el-table-column
              prop="LeaderboardIndex"
              label="LeaderboardIndex"/>
          <el-table-column
              prop="Score"
              label="Score"/>
          <el-table-column
              prop="MaxEndlessArea"
              label="MaxEndlessArea"/>
          <el-table-column
              prop="ClanId"
              label="ClanId"/>
          <el-table-column
              label="Command"
          >
            <template #default="scope">
              <el-button type="primary" round @click="handleTapShowClanScore(scope.row)">
                ClanScore
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <br/>
      <br/>

      <span>User Login</span>

      <MyFormItem label="LoginId" tooltip="">
        <el-input class="form-input" v-model="loginData.loginId" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="UserId" tooltip="">
        <el-input class="form-input" v-model="loginData.userId" size="normal" readonly/>
      </MyFormItem>

      <MyFormItem label="Token" tooltip="">
        <el-input class="form-input" v-model="loginData.token" size="normal" readonly/>
      </MyFormItem>

      <el-button type="primary" @click="onTapGetToken()">
        Get token
      </el-button>

      <br/>
      <br/>

      <span>TEST register Lb</span>

<!--      <MyFormItem label="UserId" tooltip="">-->
<!--        <el-input class="form-input" v-model="testRegisterLb.userId" size="normal" readonly/>-->
<!--      </MyFormItem>-->

      <MyFormItem label="IsPayUser" tooltip="">
        <el-checkbox v-model="testRegisterLb.isPayUser" size="large" />
      </MyFormItem>

      <MyFormItem label="Region" tooltip="">
        <el-input-number v-model="testRegisterLb.region" :step="1" size="small" :min="1"/>
      </MyFormItem>

      <MyFormItem label="Country" tooltip="">
        <el-input class="form-input" v-model="testRegisterLb.country" size="normal"/>
      </MyFormItem>

      <MyFormItem label="Gold" tooltip="">
        <el-input-number v-model="testRegisterLb.gold" :step="1" size="small" :min="-1"/>
      </MyFormItem>

      <MyFormItem label="Version" tooltip="">
        <el-input class="form-input" v-model="testRegisterLb.version" size="normal"/>
      </MyFormItem>

      <el-table :data="testRegisterLb.result" border v-if="testRegisterLb.result.length > 0">
        <el-table-column
            prop="GroupName"
            label="GroupName"/>
        <el-table-column
            prop="ClanGroupName"
            label="ClanGroupName"/>
      </el-table>

      <el-button type="primary" @click="registerLb()">
        Test Register LB
      </el-button>

      <br/>
      <br/>

      <span>TEST add score</span>

<!--      <MyFormItem label="UserId" tooltip="">-->
<!--        <el-input class="form-input" v-model="testAddScore.userId" size="normal"/>-->
<!--      </MyFormItem>-->

      <MyFormItem label="Score" tooltip="">
        <el-input-number v-model="testAddScore.score" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <MyFormItem label="MaxEndlessArea" tooltip="">
        <el-input-number v-model="testAddScore.maxEndlessArea" :step="1" size="small" :min="0"/>
      </MyFormItem>

      <el-table :data="testAddScore.result" border v-if="testAddScore.result.length > 0">
        <el-table-column
            prop="UserId"
            label="UserId"/>
        <el-table-column
            prop="GroupName"
            label="GroupName"/>
        <el-table-column
            prop="Score"
            label="Score"/>
        <el-table-column
            prop="ClanGroupName"
            label="ClanGroupName"/>
        <el-table-column
            prop="ClanScore"
            label="ClanScore"/>
        <el-table-column
            prop="ClanId"
            label="ClanId"/>
      </el-table>

      <el-button type="primary" @click="addScore()">
        Test Add Score
      </el-button>

      <br/>
      <br/>

      <div v-if="data.TypeLb === `RbLittle`">
        <br/>
        <br/>
        <span>TEST get BossInfo</span>
        <br/>
        <br/>
<!--        <MyFormItem label="UserId" tooltip="Nhập userId get infoboss">-->
<!--          <el-input class="form-input" v-model="showBossInfo.userId" size="normal" clearable/>-->
<!--        </MyFormItem>-->

        <el-button type="primary" @click="getBossInfo()">
          Get Boss Info
        </el-button>

        <br/>

        <el-table :data="showBossInfo.result" border v-if="showBossInfo.result.length > 0">
          <el-table-column
              prop="BossId"
              label="BossId"/>
          <el-table-column
              prop="GroupName"
              label="GroupName"/>
          <el-table-column
              prop="Hp"
              label="Hp"/>
          <el-table-column
              prop="Time.Start"
              label="Time Start"/>
          <el-table-column
              prop="Time.End"
              label="Time End"/>
        </el-table>

        <br/>
        <br/>

        <span>Test End battle</span>

<!--        <MyFormItem label="UserId" tooltip="Nhập userId end battle">-->
<!--          <el-input class="form-input" v-model="showEndBattle.userId" size="normal" clearable/>-->
<!--        </MyFormItem>-->

        <MyFormItem label="Damage" tooltip="Nhập Damage end battle">
          <el-input-number v-model="showEndBattle.dmg" :step="1" size="small" :min="1"/>
        </MyFormItem>

        <el-button type="primary" @click="endBattle()">
          Test End Battle
        </el-button>

        <br/>
        <br/>

        <el-table :data="showEndBattle.result" border v-if="showEndBattle.result.length > 0">
          <el-table-column
              prop="BossId"
              label="BossId"/>
          <el-table-column
              prop="GroupName"
              label="GroupName"/>
          <el-table-column
              prop="Hp"
              label="Hp"/>
          <el-table-column
              prop="Score"
              label="Score"/>
        </el-table>

        <br/>
        <br/>
      </div>

      <div v-if="data.TypeLb === `Versus`">
        <span>TEST Update Gun Power</span>
<!--        <MyFormItem label="UserId" tooltip="">-->
<!--          <el-input class="form-input" v-model="testUpdateGunPower.userId" size="normal"/>-->
<!--        </MyFormItem>-->
        <MyFormItem label="GunPower" tooltip="">
          <el-input-number v-model="testUpdateGunPower.gunPower" :step="1" size="small" :min="0"/>
        </MyFormItem>

        <el-table :data="testUpdateGunPower.result" border v-if="testUpdateGunPower.result.length > 0">
          <el-table-column
              prop="ErrorCode"
              label="ErrorCode"/>
          <el-table-column
              prop="Message"
              label="Message"/>
        </el-table>

        <el-button type="primary" @click="updateGunPower()">
          Test Update Gun Power
        </el-button>

        <br/>
        <br/>

        <span>Find match</span>
<!--        <MyFormItem label="UserId" tooltip="">-->
<!--          <el-input class="form-input" v-model="testFindMatch.userId" size="normal"/>-->
<!--        </MyFormItem>-->

        <MyFormItem label="Group name" tooltip="">
          <el-select v-model="testFindMatch.groupName">
            <el-option v-for="data in this.groupTypeSelect" :key="data.value" :label="data.value" :value="data.value"/>
          </el-select>
        </MyFormItem>

        <MyFormItem label="Leaderboard Index" tooltip="">
          <el-input-number v-model="testFindMatch.lbIndex" :step="1" size="small" :min="0"/>
        </MyFormItem>

        <el-table :data="testFindMatch.result" border v-if="testFindMatch.result.length > 0">
          <el-table-column
              prop="ErrorCode"
              label="ErrorCode"/>
          <el-table-column
              prop="CodeMessage"
              label="Message"/>
          <el-table-column
              prop="id"
              label="UserId"/>
          <el-table-column
              prop=name
              label="UserName"/>
          <el-table-column
              prop="frameID"
              label="FrameId"/>
          <el-table-column
              prop="avatarID"
              label="AvatarId"/>
          <el-table-column
              prop="profileConsumables"
              label="Profile Consumables"/>
          <el-table-column
              prop="profileDrones"
              label="Profile Drones"/>
          <el-table-column
              prop="profileGuns"
              label="Profile Guns"/>
          <el-table-column
              prop="profileHero"
              label="Profile Hero"/>
        </el-table>

        <el-button type="primary" @click="findMatch()">
          Test Find Match
        </el-button>

        <br/>
        <br/>

        <span>End battle</span>
<!--        <MyFormItem label="UserId1" tooltip="">-->
<!--          <el-input class="form-input" v-model="testEndVsBattle.user1.userId" size="normal"/>-->
<!--        </MyFormItem>-->

        <MyFormItem label="Score1" tooltip="">
          <el-input-number v-model="testEndVsBattle.user1.score" :step="1" size="small" :min="0"/>
        </MyFormItem>

        <MyFormItem label="UserId2" tooltip="">
          <el-input class="form-input" v-model="testEndVsBattle.user2.userId" size="normal"/>
        </MyFormItem>

        <MyFormItem label="Score2" tooltip="">
          <el-input-number v-model="testEndVsBattle.user2.score" :step="1" size="small" :min="0"/>
        </MyFormItem>

        <MyFormItem label="LogId" tooltip="">
          <el-input class="form-input" v-model="testEndVsBattle.logId" size="normal"/>
        </MyFormItem>

        <el-table :data="testEndVsBattle.result" border v-if="testEndVsBattle.result.length > 0">
          <el-table-column
              prop="UserId"
              label="UserId"/>
          <el-table-column
              prop="Score"
              label="Score"/>
          <el-table-column
              prop="GroupName"
              label="GroupName"/>
          <el-table-column
              prop="Win"
              label="Win"/>
          <el-table-column
              prop="Draw"
              label="Draw"/>
          <el-table-column
              prop="Lose"
              label="Lose"/>
        </el-table>

        <el-button type="primary" @click="endVsBattle()">
          Test End Vs Battle
        </el-button>

        <br/>
        <br/>

        <span>Get Vs Battle Log</span>
<!--        <MyFormItem label="UserId1" tooltip="">-->
<!--          <el-input class="form-input" v-model="testGetVsBattleLog.userId" size="normal"/>-->
<!--        </MyFormItem>-->

        <MyFormItem label="Group name" tooltip="">
          <el-select v-model="testGetVsBattleLog.groupName">
            <el-option v-for="data in this.groupTypeSelect" :key="data.value" :label="data.value" :value="data.value"/>
          </el-select>
        </MyFormItem>

        <MyFormItem label="Leaderboard Index" tooltip="">
          <el-input-number v-model="testGetVsBattleLog.lbIndex" :step="1" size="small" :min="0"/>
        </MyFormItem>

        <el-table :data="testGetVsBattleLog.result" border v-if="testGetVsBattleLog.result.length > 0">
          <el-table-column
              prop="LogId"
              label="LogId"/>
          <el-table-column
              prop="Time"
              label="Time"/>
          <el-table-column
              prop="UserId1"
              label="UserId1"/>
          <el-table-column
              prop="ScoreWin1"
              label="ScoreWin1"/>
          <el-table-column
              prop="PointGet1"
              label="PointGet1"/>
          <el-table-column
              prop="UserId2"
              label="UserId2"/>
          <el-table-column
              prop="ScoreWin2"
              label="ScoreWin2"/>
          <el-table-column
              prop="PointGet2"
              label="PointGet2"/>
          <el-table-column
              prop="RevengeCount"
              label="RevengeCount"/>
        </el-table>

        <el-button type="primary" @click="getVsBattleLog()">
          Test Get Vs Battle Log
        </el-button>

        <br/>

        <br/>

        <span>Get Vs User Revenge</span>
<!--        <MyFormItem label="UserIdRequest" tooltip="">-->
<!--          <el-input class="form-input" v-model="testGetVsUserRevenge.userIdRequest" size="normal"/>-->
<!--        </MyFormItem>-->

        <MyFormItem label="LogId" tooltip="">
          <el-input class="form-input" v-model="testGetVsUserRevenge.logId" size="normal"/>
        </MyFormItem>

        <el-table :data="testGetVsUserRevenge.result" border v-if="testGetVsUserRevenge.result.length > 0">
          <el-table-column
              prop="ErrorCode"
              label="ErrorCode"/>
          <el-table-column
              prop="CodeMessage"
              label="Message"/>
          <el-table-column
              prop="id"
              label="UserId"/>
          <el-table-column
              prop=name
              label="UserName"/>
          <el-table-column
              prop="frameID"
              label="FrameId"/>
          <el-table-column
              prop="avatarID"
              label="AvatarId"/>
          <el-table-column
              prop="profileConsumables"
              label="Profile Consumables"/>
          <el-table-column
              prop="profileDrones"
              label="Profile Drones"/>
          <el-table-column
              prop="profileGuns"
              label="Profile Guns"/>
          <el-table-column
              prop="profileHero"
              label="Profile Hero"/>
        </el-table>

        <el-button type="primary" @click="getVsRevengeUser()">
          Test Get Vs User Revenge
        </el-button>
        <br/>
        <br/>
      </div>

      <div v-if="data.TypeLb !== `RbLittle`">
        <MyFormItem label="Clan Group name" tooltip="Chọn group name cần show leaderboard">
          <el-select v-model="showClanLeaderBoard.clanGroupName">
            <el-option v-for="data in this.clanGroupTypeSelect" :key="data.value" :label="data.value" :value="data.value"/>
          </el-select>
        </MyFormItem>

        <MyFormItem label="Clan Leaderboard Index" tooltip="Chọn leaderboard index cần show leaderboard">
          <el-input-number v-model="showClanLeaderBoard.lbIndex" :step="1" size="small" :min="0"/>
        </MyFormItem>

        <el-collapse class="space-to-bottom" :accordion="false" v-if="showClanLeaderBoard.result.length > 0">
          <el-collapse-item title="View Data">
            <el-table :data="showClanLeaderBoard.result" border >
              <el-table-column
                  prop="Rank"
                  label="Rank"/>
              <el-table-column
                  prop="ClanId"
                  label="ClanId"/>
              <el-table-column
                  prop="Score"
                  label="Score"/>
              <el-table-column
                  label="Command"
              >
                <template #default="scope">
                  <el-button type="primary" round @click="handleTapShowClanScore(scope.row)">
                    ClanScore
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>

        <el-button type="primary" @click="getClanLeaderBoard()">
          Get Clan Leaderboard
        </el-button>

        <br/>
        <br/>

        <div ref="clan-info">
          <span>ClanInfo</span>

          <MyFormItem label="ClanId" tooltip="Nhập clanId để search thông tin">
            <el-input class="form-input" v-model="showClanInfo.clanId" size="normal" clearable/>
          </MyFormItem>

          <el-button type="primary" @click="getClanInfo()">
            Get Clan Info
          </el-button>

          <br/>
          <br/>

          <el-table :data="showClanInfo.result" border v-if="showClanInfo.result.length > 0">
            <el-table-column
                prop="ClanId"
                label="ClanId"/>
            <el-table-column
                prop="GroupNameOnly"
                label="GroupName"/>
            <el-table-column
                prop="TableIndex"
                label="TableIndex"/>
            <el-table-column
                prop="LeaderboardIndex"
                label="LeaderboardIndex"/>
            <el-table-column
                prop="Score"
                label="Score"/>
          </el-table>

          <el-collapse class="space-to-bottom" :accordion="false" v-if="showClanInfo.memberScore.length > 0">
            <el-collapse-item title="View Data">
              <el-table :data="showClanInfo.memberScore" border >
                <el-table-column
                    prop="UserId"
                    label="UserId"/>
                <el-table-column
                    prop="Score"
                    label="Score"/>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </div>


        <br/>
        <br/>
      </div>


    </el-form>
  </div>
</template>

<script>

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import moment from 'moment'

export default {
  components:{
    MyFormItem
  },
  props: {
    data: {},
  },
  data: () => {
    return {
      showLeaderBoard: {
        groupName: "",
        lbIndex: 0,
        result: [],
      },
      showClanLeaderBoard: {
        clanGroupName: "",
        lbIndex: 0,
        result: [],
      },
      showUserInfo: {
        userId: "",
        result: []
      },
      loginData: {
        loginId: "abc",
        token: "",
        userId: "",
      },
      testRegisterLb: {
        userId: "xyz",
        isPayUser: true,
        region: 1,
        country: "US",
        gold: -1,
        version: "",
        result: [],
      },
      testAddScore: {
        userId: "xyz",
        score: 120,
        maxEndlessArea: 0,
        result: [],
      },
      showBossInfo: {
        userId: "",
        result: [],
      },
      showEndBattle: {
        userId: "",
        dmg: 0,
        result: [],
      },
      testUpdateGunPower: {
        userId: "xyz",
        gunPower: 1,
        result: [],
      },
      testFindMatch: {
        userId: "xyz",
        groupName: "",
        lbIndex: 0,
        result: [],
      },
      testEndVsBattle: {
        user1: {
          userId: "xyz",
          score: 1,
        },
        user2: {
          userId: "abc",
          score: 1,
        },
        logId: "",
        result: [],
      },
      testGetVsBattleLog: {
        //userId: "xyz",
        groupName: "",
        lbIndex: 0,
        result: [],
      },
      testGetVsUserRevenge:{
        userIdRequest: "xyz",
        logId: "",
        result: [],
      },
      showClanInfo: {
        clanId: "",
        result: [],
        memberScore: [],
      },
    }
  },
  computed: {
    groupTypeSelect: {
      get: function() {
        let options = [];
        for (let i = 0; i < this.data.GroupConfig.length; i++) {
          options.push({
            value: this.data.GroupConfig[i].GroupName,
          });
        }

        return options;
      },
    },
    clanGroupTypeSelect: {
      get: function () {
        let options = [];
        if (this.data.ClanGroupConfig === undefined) {
          return options;
        }

        for (let i = 0; i < this.data.ClanGroupConfig.length; i++) {
          options.push({
            value: this.data.ClanGroupConfig[i].GroupName,
          });
        }

        return options;
      }
    },
  },
  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },

    async getLeaderBoard() {
      if (this.showLeaderBoard.groupName === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showLeaderBoard.result = [];
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-leaderboard-detail/${this.data.TypeLb}_${this.data.TableIndex}_${this.showLeaderBoard.groupName}_${this.showLeaderBoard.lbIndex}`,
          method: `get`,
        });

        let response = responseFull.data;

        this.$root.$emit("event-change-loading", false);

        for (let i = 0; i < response.Lb.length; i++) {
          this.showLeaderBoard.result.push({
            Rank: i + 1,
            UserId: response.Lb[i].UserId,
            Score: response.Lb[i].Score,
          });
        }
      }catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async getUserInfo() {
      if (this.showUserInfo.userId === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showUserInfo.result = [];
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-user-score/${this.showUserInfo.userId}/${this.data.TypeLb}`,
          method: `get`,
        });

        let response = responseFull.data;

        this.$root.$emit("event-change-loading", false);

        let showData = {
          UserId: response.UserId,
          IsPayUser: response.IsPayUser ? "true" : "false",
          UserStatus: response.UserStatus,
          Score: response.Score,
          MaxEndlessArea: response.MaxEndlessArea,
          ClanId: response.ClanId
        };

        let splitGroupName = response.GroupName.split('_');
        showData.GroupNameOnly = splitGroupName.slice(2, 6).join('_');
        showData.TableIndex = splitGroupName[1];
        showData.LeaderboardIndex = splitGroupName[6];

        this.showUserInfo.result.push(showData);
      }catch (ex) {
        Message({
          message: `Get user info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async onTapGetToken() {
      this.isLoading = true;
      try {
        let responseFull = await request({
          url: `/login`,
          method: `post`,
          data: {
            LoginId: this.loginData.loginId,
          },
        });

        let response = responseFull.data;

        this.loginData.token = response.Token;
        this.loginData.userId = response.UserId;
      }
      catch (ex) {
        Message({
          message: `Get token error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.isLoading = false;
    },

    async registerLb() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.testRegisterLb.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/register-lb`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            //UserId: this.testRegisterLb.userId,
            TypeLb: this.data.TypeLb,
            IsPayUser: this.testRegisterLb.isPayUser,
            Region: this.testRegisterLb.region,
            Country: this.testRegisterLb.country,
            Gold: this.testRegisterLb.gold,
            Version: this.testRegisterLb.version,
          }
        });

        this.$root.$emit("event-change-loading", false);

        this.testRegisterLb.result.push(response.data);
      }catch (ex) {
        Message({
          message: `Add score error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async addScore() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.testAddScore.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/add-score`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            //UserId: this.testAddScore.userId,
            TypeLb: this.data.TypeLb,
            Score: this.testAddScore.score,
            MaxEndlessArea: this.testAddScore.maxEndlessArea,
          }
        });

        this.$root.$emit("event-change-loading", false);

        this.testAddScore.result.push(response.data);
      }catch (ex) {
        Message({
          message: `Add score error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async getBossInfo() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.showBossInfo.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/rb/get-boss-info`,
          method: `get`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
        });

        this.$root.$emit("event-change-loading", false);

        this.showBossInfo.result.push(response.data)

      }catch (ex) {
        Message({
          message: `Get boss info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async endBattle() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.showEndBattle.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/rb/end-battle`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            //UserId: this.showEndBattle.userId,
            Dmg: this.showEndBattle.dmg,
          }
        });

        this.$root.$emit("event-change-loading", false);

        this.showEndBattle.result.push(response.data);

      }catch (ex) {
        Message({
          message: `End battle error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async updateGunPower() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.testUpdateGunPower.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/vs/update-power-gun`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            //UserId: this.testUpdateGunPower.userId,
            GunPower: this.testUpdateGunPower.gunPower,
          }
        });

        this.$root.$emit("event-change-loading", false);

        this.testUpdateGunPower.result.push(response.data);
      }
      catch (ex) {
        Message({
          message: `Test Update GunPower error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async findMatch() {
      try {
        if (this.testFindMatch.groupName === "") {
          return;
        }

        this.$root.$emit("event-change-loading", true);
        this.testFindMatch.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/vs/find-match`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            //UserIdFind: this.testFindMatch.userId,
            GroupName: `${this.data.TypeLb}_${this.data.TableIndex}_${this.testFindMatch.groupName}_${this.testFindMatch.lbIndex}`,
          }
        });

        let dataFindMatch = response.data.Data;

        if (dataFindMatch !== undefined) {
          dataFindMatch.profileConsumables = JSON.stringify(dataFindMatch.profileConsumables);
          dataFindMatch.profileDrones = JSON.stringify(dataFindMatch.profileDrones);
          dataFindMatch.profileGuns = JSON.stringify(dataFindMatch.profileGuns);
          dataFindMatch.profileHero = JSON.stringify(dataFindMatch.profileHero);
        }
        else {
          dataFindMatch = {};
        }

        dataFindMatch.ErrorCode = response.data.ErrorCode;
        dataFindMatch.CodeMessage = response.data.CodeMessage;

        this.$root.$emit("event-change-loading", false);

        this.testFindMatch.result.push(dataFindMatch);
      }
      catch (ex) {
        Message({
          message: `Test Find match error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async endVsBattle() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.testEndVsBattle.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/vs/end-battle`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            LogId: this.testEndVsBattle.logId,
            UserDetail: [
              {
                //UserId: this.testEndVsBattle.user1.userId,
                ScoreWin: this.testEndVsBattle.user1.score,
              },
              {
                UserId: this.testEndVsBattle.user2.userId,
                ScoreWin: this.testEndVsBattle.user2.score,
              }
            ]
          }
        });

        this.$root.$emit("event-change-loading", false);
        this.testEndVsBattle.result.push(response.data);

      }catch (ex) {
        Message({
          message: `Test end VS battle error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async getVsBattleLog() {
      try {
        if (this.testGetVsBattleLog.groupName === "") {
          return;
        }

        this.$root.$emit("event-change-loading", true);
        this.testGetVsBattleLog.result = [];
        let groupName = `${this.data.TypeLb}_${this.data.TableIndex}_${this.testGetVsBattleLog.groupName}_${this.testGetVsBattleLog.lbIndex}`;
        let response = await request({
          url: `/leaderboard/personal/v2/vs/get-battle-log/${groupName}`,
          method: `get`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
        });

        this.$root.$emit("event-change-loading", false);

        for(let i = 0; i < response.data.BattleLog.length; i++) {
          let logData = response.data.BattleLog[i];
          this.testGetVsBattleLog.result.push({
            LogId: logData.LogId,
            Time: moment.unix(logData.Time).format(),
            UserId1: logData.UserDetail[0].UserId,
            ScoreWin1: logData.UserDetail[0].ScoreWin,
            PointGet1: logData.UserDetail[0].PointGet,
            UserId2: logData.UserDetail[1].UserId,
            ScoreWin2: logData.UserDetail[1].ScoreWin,
            PointGet2: logData.UserDetail[1].PointGet,
            RevengeCount: logData.RevengeCount,
          });
        }
      }
      catch (ex) {
        Message({
          message: `get vs battle log error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
    async getVsRevengeUser() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.testGetVsUserRevenge.result = [];
        let response = await request({
          url: `/leaderboard/personal/v2/vs/get-user-revenge`,
          method: `post`,
          headers: {
            "Authorization" : `Bearer ${this.loginData.token}`
          },
          data: {
            //UserIdRequest: this.testGetVsUserRevenge.userIdRequest,
            LogId: this.testGetVsUserRevenge.logId,
          }
        });

        let data = response.data.Data;

        if (data !== undefined) {
          data.profileConsumables = JSON.stringify(data.profileConsumables);
          data.profileDrones = JSON.stringify(data.profileDrones);
          data.profileGuns = JSON.stringify(data.profileGuns);
          data.profileHero = JSON.stringify(data.profileHero);
        }
        else {
          data = {};
        }

        data.ErrorCode = response.data.ErrorCode;
        data.CodeMessage = response.data.CodeMessage;

        this.$root.$emit("event-change-loading", false);

        this.testGetVsUserRevenge.result.push(data);
      }
      catch (ex) {
        Message({
          message: `Test Get Vs Revenge User error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    async handleTapShowClanScore(row) {
      try {
        this.showClanInfo.clanId = row.ClanId;
        await this.getClanInfo();

        this.scrollMeTo("clan-info");
      }
      catch (ex) {
        Message({
          message: `Get clan info detail error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    async handleTapShowUserDetail(row) {
      try {
        this.showUserInfo.userId = row.UserId;
        await this.getUserInfo();

        this.scrollMeTo("user-info");
      }
      catch (ex) {
        Message({
          message: `Get user info detail error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    async getClanInfo() {
      if (this.showClanInfo.clanId === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showClanInfo.result = [];
        this.showClanInfo.memberScore = [];
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-clan-score/${this.showClanInfo.clanId}/${this.data.TypeLb}`,
          method: `get`,
        });

        let response = responseFull.data;

        this.$root.$emit("event-change-loading", false);

        let showData = {
          ClanId: response.ClanId,
          Score: response.Score,
        };

        let splitGroupName = response.ClanGroupName.split('_');
        showData.GroupNameOnly = splitGroupName.slice(2, 4).join('_');
        showData.TableIndex = splitGroupName[1];
        showData.LeaderboardIndex = splitGroupName[4];

        this.showClanInfo.result.push(showData);

        for (let i = 0; i < response.memberScore.length; i++) {
          this.showClanInfo.memberScore.push(response.memberScore[i]);
        }
      }
      catch (ex) {
        Message({
          message: `Get clan info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    async getClanLeaderBoard() {
      if (this.showClanLeaderBoard.groupName === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showClanLeaderBoard.result = [];
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-clan-leaderboard-detail/${this.data.TypeLb}_${this.data.TableIndex}_${this.showClanLeaderBoard.clanGroupName}_${this.showClanLeaderBoard.lbIndex}`,
          method: `get`,
        });

        let response = responseFull.data;

        this.$root.$emit("event-change-loading", false);

        for (let i = 0; i < response.Lb.length; i++) {
          this.showClanLeaderBoard.result.push({
            Rank: i + 1,
            ClanId: response.Lb[i].ClanId,
            Score: response.Lb[i].Score,
          });
        }
      }catch (ex) {
        Message({
          message: `Get Clan leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>